export const onServiceWorkerUpdateReady = () => {
    // Automatically reload to apply changes without user intervention
    // skip the waiting phase and immediately activate, even if there are still open pages controlled by the old service worker
    // When the new service worker activates, it takes control of the page and replaces any cached assets with the ones specified in its cache manifest, effectively invalidating the old cache.
    navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' })

    console.log('New service worker version is installed.')

    caches
        .keys()
        .then((cacheNames) => {
            return Promise.all(
                cacheNames.map((cacheName) => caches.delete(cacheName))
            )
        })
        .then(() => {
            console.log('Cache cleared. Reloading the page...')
            // After activating the new service worker, reloading the page ensures that the updated resources managed by the new service worker are loaded.
            window.location.reload()
        })
}
